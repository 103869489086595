.description-content {
  span {
    background-color: transparent !important;
    color: #000000 !important;

    &> * {
      white-space: pre-wrap;
      color: #000000 !important;
    }
  
    &> span {
      display: inline !important;
      vertical-align: unset !important;
    }

    strong {
      font-weight: 700 !important;
      &> * {
        font-weight: 700 !important;
      }
    }
  }
}