@import '../../../assets/styles/_variables';

.chat-filter-settings-modal {
  .modal-dialog {
    .modal-header {
      padding: 10px 30px 0;
    }
    .form-group {
      margin-bottom: 5px;
      .label-fix {
        left: 10px;
        top: 9px;
        margin-bottom: 0;
        padding: 0 5px;
        display: block;
        transition: 0.3s;
        font-size: 12px;
        color: #9b9b9b;
      }
      .label {
        font-weight: 600;
      }
      .textarea {
        display: block;
        width: 100%;
        padding: 9px 15px 9px;
        background: #fff;
        border: 1px solid #cecccc;
        border-radius: 3px;
        outline: 0;
        color: #3a3a3a;
        font-size: 13px;
        min-height: 91px;
      }
    }
  }
  .modal-body {
    padding-left: 30px;
    padding-right: 30px;
  }
  .modal-footer {
    padding: 10px 30px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    text-align: right;
    border: 0;

    .btn {
      margin-left: 12px;
      box-shadow: none;
    }
    .btn.btn_blue {
      opacity: 1;
      background: #00506b;
      color: #fff;
      &:hover:not(:disabled) {
        opacity: 0.8;
        background: $dark_blue;
        color: $white;
      }
      &:disabled {
        opacity: 0.8;
        cursor: default;
        background: $light-gray-3;
        box-shadow: none;
        color: $grey-5;
      }
    }
    .btn.btn_white {
      border: 1px solid #cecccc;
      background: transparent;
      color: #505050;
    }
  }
}
