.dropdown-settings-menu.dropdown {
  position: relative;
  height: initial;
  .btn-menu.dropdown-toggle {
    min-width: 40px;
    height: 40px;
    padding: 0;
    opacity: 0.8;
    background-color: #fff;
    border: 0;
    border-radius: 3px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
    color: #555;
    text-align: center;

    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled).focus,
    &:not(:disabled):not(.disabled):active:focus {
      border: 0;
      border-radius: 3px;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
    }

    &:after {
      display: none;
    }
  }

  &.show {
    .btn-menu.dropdown-toggle {
      border-radius: 3px 3px 0 0 !important;
    }
  }

  &.accounts {
    .dropdown-menu.show {
      min-width: 160px;
      transform: translate(-71%, 0) !important;
    }
  }

  .dropdown-menu.show {
    left: auto !important;
    right: 0 !important;
    top: 0 !important;
    inset: unset !important;
    transform: translate(-80%, 0) !important;
  }

  .dropdown-menu.show {
    right: -4px;
    position: absolute;
    z-index: 2;
    margin-top: 0;
    width: 100%;
    min-width: 200px;
    background-color: #fff;
    border-radius: 3px 0 3px 3px;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.08);
    color: #555;
    overflow: hidden;
    .dropdown-item {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      min-width: 140px;
      padding: 0 10px;
      border-radius: 0;
      border-bottom: 1px solid rgba(33, 37, 41, 0.1);
      color: #212529;
      font-weight: 400;
      text-align: left;
      .btn {
        min-width: 0;
        padding: 0;
        color: inherit;
        font-weight: inherit;
        &:hover {
          border: 0;
        }
      }
    }
  }
}
