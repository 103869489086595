.loaderPositionCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70px;
  height: 70px;
  margin-left: -24px;
}
.loaderPositionCenter svg {
  display: block;
  height: 70px;
}
.loaderPositionCenter .fill {
  fill: #636363;
}
