@import '../../assets/styles/styles';
.card.card_chart {
  height: calc(100% - 25px);
  margin-bottom: 25px;
  .card-body {
    min-height: 489px;
    padding: 30px;
  }
  .chart_label {
    color: #3a3a3a;
    .tooltip_info {
      display: inline-block;
      margin-left: 6px;
      margin-bottom: 10px;
    }
    strong {
      display: block;
      font-family: 'Averta-Semibold';
      font-weight: normal;
    }
    + .chart_label {
      margin-top: 20px;
    }
  }
  .chart {
    margin: -0px -15px -30px -25px;
  }
}
@media (max-width: 992px) {
  .card.card_chart {
    .chart_label {
      & + .chart_label {
        margin-top: 0;
      }
      > div {
        padding-bottom: 10px;
      }
      strong {
        display: inline-block;
      }
    }
  }
}
@media (min-width: 776px) and (max-width: 992px) {
  .card.card_chart {
    .chart {
      margin-top: -80px;
      margin-bottom: -100px;
    }
    .card-body {
      min-height: 380px;
    }
  }
}
@media (max-width: 414px) {
  .card.card_chart {
    .chart_label {
      .tooltip_info {
        display: block;
      }
    }
  }
}
