h1 {
  font-family: 'Averta', sans-serif;
  font-weight: 600;
}
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  line-height: normal;
  font-family: 'Averta';
  letter-spacing: 0.2px;
}
html,
body {
  height: 100%;
  overflow: hidden;
}
body {
  background-color: #f9f9f9 !important;
  font-family: 'Averta' !important;
  font-weight: 400;
  font-size: 14px !important;
}
img {
  max-width: 100%;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 0;
  margin-bottom: 0.9rem;
  font-size: 1.2rem;
  color: #444444;
}
.pull-right {
  float: right;
}
@-webkit-keyframes autofill {
  to {
    background: transparent;
  }
}

input:focus {
  outline: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
.align-center {
  display: block;
  margin: 0 auto;
}
.no_data {
  p {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 30px;
    margin-bottom: 0;
    color: #555;
    font-size: 15px;
    transform: translate(-50%, -50%);
  }
}
.page-content {
  position: absolute;
  top: 60px;
  left: 100px;
  right: 0;
  padding-top: 40px;
  padding-bottom: 40px;
  height: calc(100vh - 60px);
  overflow: auto;
  .row {
    margin-right: -12px;
    margin-left: -12px;
  }
  .col,
  [class^='col-'] {
    padding-right: 12px;
    padding-left: 12px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .container {
    max-width: 100%;
  }
}
@media (max-width: 992px) {
  .page-content {
    left: 0;
  }
}
@media (max-width: 767px) {
  .page-content {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
