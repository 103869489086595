.profile {
  position: relative;
  margin-left: 10px;
  .profile_image {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background: white;
    overflow: hidden;
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      right: -3px;
      bottom: 0;
      display: block;
      width: 14px;
      height: 8px;
      border-radius: 2px 0 0 0;
      background: #f2f2f2;
      transform: skew(35deg);
    }
    &:before {
      content: '';
      position: absolute;
      right: 0px;
      bottom: 0;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 5px 0 5px;
      border-color: #5d5d5d transparent transparent transparent;
      z-index: 1;
    }
  }
  .profile_content {
    position: absolute;
    z-index: 999;
    width: 280px;
    margin-top: 17px;
    margin-left: -250px;
    padding: 16px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.1);
  }
  .avatar-info {
    position: relative;
    min-height: 55px;
    padding-left: 64px;
    padding-top: 8px;
    .avatar {
      position: absolute;
      top: 0;
      left: 0;
      width: 55px;
      height: 55px;
      border-radius: 8px;
      border: 1px solid #cecccc;
      background: white;
      overflow: hidden;
    }
    * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    h2 {
      margin-bottom: 10px;
      color: #2d2d2d;
      font-size: 14px;
    }
    p {
      margin-bottom: 0;
      color: #7c7c7c;
      font-size: 14px;
    }
  }
  .btn {
    padding: 0;
    min-width: calc(50% - 10px);
    border: 0;
  }
  .profile-action {
    margin-top: 14px;
    .btn + .btn {
      margin-left: 20px;
    }
  }
  .profile-footer {
    display: flex;
    justify-content: flex-end;
    margin: 14px -16px -16px;
    padding: 10px 16px;
    background: #f3f3f3;
  }
}
