.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding-right: 20px;
  background: #ececec;
  .logo {
    width: 100px;
    height: 60px;
    padding: 20px 25px;
    background: #001419;
    a {
      display: block;
    }
  }
  .right_parth {
    display: flex;
    align-items: center;
    .btn.add-partner {
      height: 30px;
      min-width: 110px;
      margin-right: 20px;
      padding: 0;
      line-height: 1;
    }
  }
  .btn.btn_hamburger {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 52px;
    width: 70px;
    min-width: 70px;
    padding: 10px 15px;
    cursor: pointer;
    cursor: pointer;
    > span {
      display: block;
      height: 2px;
      width: 20px;
      background: #3d3d3d;
      transition: all 0.2s ease 0s;
      transform: none;

      &:nth-child(1) {
        transform-origin: left top;
        margin-bottom: 5px;
      }
      &:nth-child(2) {
        opacity: 1;
      }
      &:nth-child(3) {
        transform-origin: left top;
        margin-top: 5px;
      }
    }
  }
}
@media (max-width: 992px) {
  .header {
    .btn.btn_hamburger {
      display: flex;
    }
    .logo {
      display: none;
    }
  }
}
