.card.card_account {
  max-width: 570px;
  margin: 0 auto;
  &.sm_header {
    .card-header {
      height: 46px;
    }
  }
  .card-header {
    height: 97px;
    h5 {
      margin: 11px 0 0;
      text-align: center;
    }
    .avatar {
      position: relative;
      left: 50%;
      display: block;
      width: 80px;
      height: 80px;
      margin-top: -30px;
      cursor: pointer;
      overflow: hidden;
      transform: translateX(-50%);
      .images {
        position: absolute;
        overflow: hidden;
        border-radius: 6px;
        border: 1px solid #cecccc;
        background: white;
        height: 100%;
        width: 100%;
      }
      .label_upload_photo {
        position: absolute;
        bottom: -1px;
        left: -1px;
        right: -1px;
        height: 20px;
        background: #888;
        border-radius: 0 0 8px 8px;
        color: #fff;
        font-size: 10px;
        line-height: 20px;
        text-align: center;
      }
    }
  }
  hr {
    margin: 25px -24px;
  }
  .action_btn {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    .btn.btn-dark-blue {
      min-width: 150px;
    }
  }
}
.form_url {
  .link {
    cursor: pointer;
    color: #00506b;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: 8px;
    margin-right: 10px;
    transform: translateY(-50%);
  }
  .form-control {
    padding-right: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 767px) {
  .card.card_account {
    margin-top: 30px;
  }
}
