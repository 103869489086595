.mb-0 {
  margin-bottom: 0;
}

.nav-chat {
  .nav-chat-filter {
    background: white;
    border-radius: 5px;
    .nav-chat-btn {
      min-width: 40px;
      height: 40px;
      padding: 0;
      opacity: 0.8;
      background-color: #fff;
      border: 0;
      border-radius: 3px;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
      color: #555;
      text-align: center;
      img {
        width: 15px;
      }
    }
  }
}

.nav-settings-dropdown {
  background: white;
  border-radius: 5px;
}

.nav-settings-icon {
  background: #fff;
  img {
    width: 15px;
  }
}
.dashboard .table-content {
  min-height: 400px;
}
.jobs-stats {

  .queue-stats {
    display: flex;
    margin-bottom: 10px;

    .queue-stats-title {
      border-radius: 3px;
      padding: 10px 20px;
      background-color: #ececec;
      text-align: center;
      color: #000000;
      font-weight: 700;
      min-width: 100px;
      margin-right: 20px;
    }

    .queue-stats-label {
      border-radius: 50px;
      padding: 10px 10px;
      color: #ffffff;
      min-width: 120px;
      margin-right: 10px;
      text-align: center;
      &.waiting {
        background-color: #673ab7;
      }
      &.active {
        background-color: #00bcd4;
      }
      &.completed {
        background-color: #4caf50;
      }
      &.failed {
        background-color: #f44336;
      }
      &.delayed {
        background-color: #1565c0;
      }
    }
  }
  
}
.jobs-controls {
  display: flex;
  flex-direction: row;
  align-items: center;

  .action-set {
    position: relative;
    margin-right: 50px;
    box-shadow: 5px 5px 4px 0 rgba(0, 0, 0, 0.06);
  }

  button.action {
    color: white;
    background: #00506b;
    cursor: pointer;
    transition: opacity 0.3s !important;
  }

  button.action:hover {
    opacity: 0.8 !important;
    color: white !important;
    background: #00506b !important;
  }

  .button-run {
    display: inline-block;
    position: absolute;
    right: -22px;
    border-radius: 3px;
    color: #00506b;
    background: #ececec;
    height: 40px;
    width: 25px;
    
    transition: all 0.4s !important;
    line-height: 1;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    opacity: 0.9;
  }

  .button-run.disabled {
    cursor: none;
    color: #a1a1a1;
    pointer-events: none;
  }

  .button-run.enabled {
    cursor: pointer;
  }

  .button-run.enabled:hover {
    opacity: 1 !important;
    color: #000000 !important;
    background: #888888 !important;
    width: 40px;
    right: -37px;
  }
}
.table-content.status {
  button.action {
    color: white;
    background: #00506b;
    cursor: pointer;
    box-shadow: 5px 5px 4px 0 rgba(#000, 0.05);
    transition: opacity 0.3s !important;
    margin-right: 10px;
    margin-top: 10px;
  }

  button.action:hover {
    opacity: 0.8 !important;
    color: white !important;
    background: #00506b !important;
  }

  .row-item.healthy {
    background-color: #ebf8e7;
  }
  .row-item.sick {
    background-color: #f1dada;
  }

  .col-selector {
    max-width: 25px;
  }
  .col-one {
    max-width: 30%;
  }
  .col-two {
    max-width: 60%;
  }

  .table-filter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 146px;
    padding: 0 24px;
    .table-filter-group {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .table-filter-filters {
        display: flex;
        justify-content: flex-start;
        .dropdown {
          margin-bottom: 5px;
        }
        .filter-dropdown {
          max-width: 120px;
          width: 50%;
          .btn {
            min-width: 120px;
          }
        }
        .filter-dropdown.filter-dropdown-wide {
          max-width: 200px;
          width: 100%;
          .btn {
            min-width: 200px;
          }
        }
      }
      .table-filter-controls {
        display: flex;
        justify-content: flex-end;
        .dropdown {
          margin-bottom: 5px;
        }
        .filter-dropdown {
          max-width: 100px;
          width: 100%;
          .btn {
            min-width: 100px;
          }
        }
      }
    }


  }
}
.table-content {
  position: relative;
  background: #fff;
  min-height: calc(100vh - 200px);
  border-radius: 5px;
  box-shadow: 5px 5px 4px 0 rgba(#000, 0.05);
  .btn_dropdown {
    width: 100%;
    text-align: left;
  }
  .table-filter {
    display: flex;
    justify-content: space-between;
    height: 62px;
    padding: 0 24px;
    border-radius: 5px 5px 0 0;
    background: #00506b;

    .filter-dropdown {
      display: inline-block;
      max-width: 215px;
      width: 30%;
      .width-235 {
        width: 235px;
      }
      + .filter-dropdown {
        margin-left: 15px;
      }
    }
    .filter-button {
      display: none;
      margin-top: 22px;
      img {
        width: 24px;
      }
    }
    .filter-content {
      width: 75%;
    }
  }
  .row-item {
    margin: 0 !important;
    padding: 24px 12px;
    background: #fff;
    border-bottom: 1px solid #dddddd;
    &:nth-child(2n + 1) {
      background: rgba(#dddddd, 0.05);
    }
    .col-one {
      width: 30%;
    }
    .col-four {
      max-width: 225px;
    }
    .col-five {
      max-width: 60px;
      img {
        max-width: 15px;
      }
    }
    .col-selector {
      max-width: 25px;
    }
    p {
      width: 100%;
      margin-bottom: 0;
      color: #3a3a3a;
      font-size: 14px;
      label {
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        margin-bottom: 0;
        color: #707070;
      }
      span {
        display: inline-block;
        vertical-align: middle;

        &.ellipsis{
          max-width: 450px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &.audience,
        &.status {
          padding: 5px 10px;
          margin: 0 5px 5px 0;
          background-color: #ececec;
          border-radius: 4px;
          font-weight: 600;
        }
        &.audience.exclude {
          text-decoration: line-through;
        }
        &.status.green {
          background-color: #87d372;
        }
        &.status.yellow {
          background-color: #dfde75;
        }
        &.status.red {
          background-color: #df7575;
        }
      }
      + p {
        margin-top: 10px;
      }
    }
  }
}
.load-more {
  margin-top: 30px;
  text-align: center;
}
.pagination {
  margin-top: 30px;
  justify-content: center;
  .page-item {
    margin: 0 8px;
  }
  .page-item:first-child .page-link,
  .page-item:last-child .page-link {
    border-radius: 50%;
  }
  .page-item .page-link {
    width: 32px;
    height: 32px;
    line-height: 32px;
    padding: 0;
    background-color: #fff;
    border: 0;
    box-shadow: 2px 2px 2px 0 rgba(#000000, 0.02);
    border-radius: 50%;
    z-index: 1;
    color: #707070;
    text-align: center;
  }
  .page-item.active .page-link {
    background-color: #00506b;
    color: #fff;
  }
}
.col-border-right {
  border-right: 1px solid #dddddd;
}
@media (min-width: 767px) and (max-width: 1023px) {
  .table-content .row-item p label {
    display: block;
  }
}
@media (max-width: 767px) {
  .table-content {
    position: relative;
    .table-filter {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      z-index: 3;
      .form-group {
        margin-bottom: 0;
      }
      .bulk-select {
        padding: 0;
      }
      .filter-content {
        opacity: 0;
        display: none;
        width: 100%;
        position: absolute;
        top: 62px;
        left: 0;
        right: 0;
        z-index: 9;
        transition: 0.3s all;
        margin-top: -1px;
        padding: 0 24px 20px;
        background: #00506b;
        &.shown {
          opacity: 1;
          display: block;
          max-height: 500px;
        }
      }
      .search-form {
        width: 100%;
        margin-right: 20px;
        padding-top: 13px;
      }
      .filter-button {
        display: block;
      }
      .filter-dropdown {
        max-width: 100%;
        width: 100%;
        .width-235 {
          width: 100%;
        }
        & + .filter-dropdown {
          margin-left: 0;
        }
      }
      .form-dropdown.dropdown {
        margin-bottom: 0;
      }
    }
    .table-list {
      overflow: auto;
      max-height: calc(100vh - 170px);
      padding-top: 62px;
      &.no-padding {
        padding-top: 0;
      }
    }
    .row-item {
      display: block;
      .col + .col {
        padding-top: 10px;
      }
      .col-two {
        padding-top: 10px;
      }
      .col-one {
        width: 100%;
      }
      .col-four {
        max-width: 100%;
      }
      p {
        label,
        span {
          white-space: nowrap;
        }
      }
    }
  }
}
