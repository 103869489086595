$primary: #004080;
$white: #fff;
$black: #000;

$dark_blue: #00506b;
$bootstrap_blue: #337ab7;
$light-blue: #2b93b9;
$light-blue-1: #007eff;

$light-gray: #fcfcfc;
$light-gray-1: #f1f1f1;
$light-gray-2: #f5f5f5;
$light-gray-3: #ececec;
$light-gray-4: #cecccc;
$light-gray-5: #e6e6e6;

$grey: #2d2d2d;
$grey-1: #3a3a3a;
$grey-2: #474747;
$grey-6: #505050;
$grey-3: #767676;
$grey-4: #999999;
$grey-5: #b3b3b3;

$color-content: $grey-6;
$label: $grey-3;

$green: #4bb543;

$dark-red: #cc3434;
$red: #e56768;

$orange: #f8bd32;

$nav-color: #001419;

/* status colors */
$online: $green;
$offline: $red;
$away: $orange;

$warning: $orange;
$successful: $green;
$error: $red;

/* vertical left navigation size */
$verticalMenuWidth: 110px;
$mobileVerticalMenuWidth: 70px;

/* filter size*/
$filterWidth: 260px;

/* space between the widgets */
$spaceContent: 20px;
$spaceWidget: 20px;
$heightTopHeader: 60px;
$filterAccordionHeadTitle: 60px;

//Font-family
$font-family-content: 'Montserrat', sans-serif;

//Font-size
$txt-xxxxl: 32px;
$txt-xxxl: 25px;
$txt-xxl-xxxl: 23px;
$txt-xxl: 22px;
$txt-xl-xxl: 20px;
$txt-xl: 19px;
$txt-lg-xl: 18px;
$txt-lg: 17px;
$txt-size: 16px;
$txt-md: 15px;
$txt-md-sm: 14px;
$txt-sm: 13px;
$txt-sm-xs: 12px;
$txt-xs: 11px;
$txt-xxs: 10px;
