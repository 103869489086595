.height_size_full {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: auto;
  max-width: initial;
}
.width_size_full {
  width: 100%;
}
