.nav-custom {
  background: #002028;
  height: calc(100vh - 60px);
  width: 100px;
  overflow: auto;

  .nav {
    .nav-item {
      width: 100px;
      height: 100px;
      &.nav_logo {
        display: none;
        width: 100%;
        height: 60px;
        padding: 24px 25px 16px;
        background: #002028;
        cursor: pointer;
        img {
          margin-bottom: 0;
        }
      }
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        color: #fff;
        font-size: 14px;
        align-items: center;
        &.active,
        &:hover {
          background: #19363e;
          text-decoration: none;
        }
      }
      img {
        display: block;
        margin: 0 auto 9px;
        &.dashboradIcon {
          width: 32px;
        }
        &.accountsIcon {
          width: 41px;
        }
        &.partnersIcon {
          width: 37px;
        }
        &.reportsIcon {
          width: 32px;
        }
        &.settingsIcon {
          width: 36px;
        }
        &.studentsIcon {
          width: 38px;
        }
        &.notificationsIcon {
          width: 40px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .nav-custom {
    position: absolute;
    top: 0;
    width: 0;
    height: 100vh;
    z-index: 999;
    transition: 0.3s;
    .nav .nav-item.nav_logo {
      display: block;
    }
  }
  .menu-shown .nav-custom {
    width: 100px;
  }
}
