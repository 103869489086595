.apps {
  position: relative;
  .apps_logo {    
    display: flex;
    align-items: center;
    justify-content: center;
    width: 31px;
    height: 31px;
    opacity: .8;
    padding: 0;
    background: #fff;
    border-radius: 4px;
    cursor: pointer;
    img {
      display: block;
      width: 19px;
      margin: 0 auto;
    }
    &:hover {
      opacity: 1;
    }
  }
  .apps_content {
    position: absolute;
    z-index: 999;
    width: 170px;
    margin-top: 6px;
    margin-left: -138px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.1);
    li {
      position: relative;
      height: 42px;
      line-height: 42px;
      padding-left: 52px;
      border-bottom: 1px solid rgba(#dddddd, 0.2);
      cursor: pointer;
      img {
        position: absolute;
        top: 50%;
        left: 13px;
        height: 18px;
        width: 18px;
        transform: translateY(-50%);
        &.jira {
          height: 21px;
          width: 21px;
        }
      }
      &:last-child {
        border: 0;
      }
      a {
        font-size: 14px;
        color: #707070;
        &:hover {
          text-decoration: none;
        }
      }
      &:hover {
        background: #dddddd;
        a {
          color: #00506b;
        }
      }
    }
  }
}
