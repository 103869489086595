.partner_modal {
  .modal-content {
    border: 0;
    border-radius: 5px;
  }
  .modal-header {
    display: block;
    height: 97px;
    padding: 0px;
    background: #00506b;
    border: 0;
    border-radius: 5px 5px 0 0;
    h5 {
      margin: 11px 0 0;
      color: #fff;
      text-align: center;
    }
    .avatar {
      position: relative;
      left: 50%;
      display: block;
      width: 80px;
      height: 80px;
      margin-top: -30px;
      overflow: hidden;
      transform: translateX(-50%);
      cursor: pointer;
      .images {
        position: absolute;
        overflow: hidden;
        border-radius: 6px;
        border: 1px solid #cecccc;
        background: white;
        height: 100%;
        width: 100%;
      }
      .label_upload_photo {
        position: absolute;
        bottom: -1px;
        left: -1px;
        right: -1px;
        height: 20px;
        background: #888;
        border-radius: 0 0 8px 8px;
        color: #fff;
        font-size: 10px;
        line-height: 20px;
        text-align: center;
      }
    }
    .btn-clear {
      position: absolute;
      top: 15px;
      right: -10px;
      filter: brightness(0) invert(1);
    }
  }
  .modal-body {
    padding: 25px 24px 14px;
  }
  .modal-footer {
    padding: 0 24px 24px;
    border-top: 0;
  }
  .dropdown {
    margin-bottom: 0;
  }
}
@media (min-width: 576px) {
  .partner_modal .modal-dialog {
    max-width: 100%;
    width: 560px;
    top: 50%;
    margin: 0 auto;
    transform: translateY(-50%) !important;
    text-align: center;
  }
}
@media (max-width: 992px) {
  .partner_modal .modal-content {
    margin-top: 60px;
    margin-bottom: 30px;
    .modal-header .btn-clear {
      right: 10px;
    }
  }
}
