@import '../../../assets/styles/_variables';

.notification-settings-modal {
  .checkbox-period-radio {
    .form-check-input {
      margin-top: 0.1rem;
    }
  }
  .modal-dialog {
    .modal-header {
      padding: 30px 30px 0;
    }
    .form-group {
      margin-bottom: 5px;
      .btn.btn_link {
        position: absolute;
        top: 12px;
        right: 10px;
      }
      .label-fix {
        background: white;
        position: absolute;
        left: 10px;
        top: 9px;
        margin-bottom: 0;
        padding: 0 5px;
        display: block;
        transition: 0.3s;
        font-size: 12px;
        color: #9b9b9b;
      }
      .label {
        padding: 0 0 10px;
        font-weight: 600;
      }

      .quill {
        .ql-toolbar.ql-snow {
          border-radius: 3px 3px 0 0;
        }
        .ql-container.ql-snow {
          border-radius: 0 0 3px 3px;
        }
        .ql-tooltip {
          z-index: 10000 !important;
        }
        .ql-editor {
          a {
            text-decoration: none;
            color: unset !important;
          }
        }
      }
    }
  }
  .modal-body {
    padding-left: 30px;
    padding-right: 30px;
  }
  .modal-footer {
    padding: 30px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    text-align: right;
    border: 0;

    .btn {
      margin-left: 12px;
      box-shadow: none;
    }
    .btn.btn_blue {
      opacity: 1;
      background: #00506b;
      color: #fff;
      &:hover:not(:disabled) {
        opacity: 0.8;
        background: $dark_blue;
        color: $white;
      }
      &:disabled {
        opacity: 0.8;
        cursor: default;
        background: $light-gray-3;
        box-shadow: none;
        color: $grey-5;
      }
    }
    .btn.btn_white {
      border: 1px solid #cecccc;
      background: transparent;
      color: #505050;
    }
  }
}
