@import '../../assets/styles/_variables';

.check_box {
  display: block;
  position: relative;
  margin-bottom: 0;
  padding-left: 22px;
  cursor: pointer;
  font-size: $txt-sm;
  font-weight: 400;
  line-height: normal;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  + .check_box {
    margin-top: 6px;
  }
  .checkbox_label {
    position: relative;
    top: 1px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 23px;

    div {
      display: inline-block;
      vertical-align: middle;
    }
    span:not(.react-custom-trigger) {
      position: relative;
      // top: 1px;
      margin-left: 8px;
    }
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    border: none;
  }
  @keyframes wave {
    50% {
      transform: scale(0.9);
    }
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    height: 14px;
    width: 14px;
    // margin-top: -1px;
    background-color: $white;
    border-radius: 2px;
    border: 1px solid $grey-6;
    transform: translateY(-50%);

    svg {
      position: absolute;
      top: 1px;
      left: 1px;
      fill: none;
      transform: scale(1);
      stroke: $grey-6;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 16px;
      stroke-dashoffset: 16px;
    }
  }

  &.disabled {
    pointer-events: none;
    cursor: initial;
    .checkbox_label {
      color: $grey-4;
    }
    .checkmark {
      border-color: $grey-4;
      svg {
        stroke: $grey-4;
      }
    }
  }
}

.check_box:hover input ~ .checkmark {
  background-color: $white;
}

.check_box input:checked ~ .checkmark {
  background-color: $white;
  /*animation: wave .4s ease;*/
  svg {
    stroke-dashoffset: 0;
  }
}
.check_box input:checked ~ .checkmark:after {
  display: block;
}
