button {
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
:global {
  a:hover {
    box-shadow: none;
    text-decoration: none;
  }
  .btn.btn_sm {
    height: 31px;
    border-radius: 3px;
    font-size: $txt-sm-xs;
    font-weight: 600;
  }
  .btn.btn_lg {
    height: 46px;
    line-height: 46px;
    border-radius: 4px;
    font-size: $txt-sm;
  }
  .btn {
    position: relative;
    display: block;
    height: 40px;
    line-height: 40px;
    min-width: 120px;
    padding: 0 10px;
    background: none;
    border: 0;
    border-radius: 3px;
    font-weight: 600;
    transition-duration: 0.4s;
    cursor: pointer;
    text-shadow: none;
    text-decoration: none;
    @include ellipsis;
    &[disabled] {
      background: none;
    }
    &.active:focus,
    &:active:focus,
    &:focus {
      outline: none;
    }
    &:hover {
      box-shadow: none;
      text-decoration: none;
    }
    &.btn_link {
      min-width: 0;
      height: auto;
      padding: 0;
      border: 0;
      background: none;
      box-shadow: none;
      color: $dark_blue;
      line-height: 1;
      &:active:focus {
        background: none;
        color: $dark_blue;
      }
    }
    &.btn_white {
      border: 1px solid$light-gray-4;
      background: transparent;
      box-shadow: none;
      color: $color-content;
      &:hover {
        background: rgba($white, 0.7);
        color: $color-content;
      }
    }
    &.btn_gray {
      border: 1px solid $light-gray-1;
      background: $light-gray-2;
      box-shadow: none;
      color: $color-content;
      &:hover {
        background: rgba($light-gray-2, 0.7);
        color: $color-content;
      }
      &.btn_prev:before {
        border-color: $color-content;
      }
    }
    &.btn_clear {
      position: relative;
      top: -1px;
      width: 12px;
      height: 12px;
      min-width: 0;
      margin-left: 20px;
      box-shadow: none;
      line-height: 1;
      background: url(../../assets/images/icon-msg/cancel-red.svg) no-repeat;
      &:focus,
      &:hover {
        background-color: transparent;
      }
    }
    &.btn_light_blue {
      margin-right: 16px;
      background: rgba($dark_blue, 0.2);
      color: $dark_blue;
      &:hover:not(:disabled) {
        opacity: 1;
        background: rgba($dark_blue, 0.3);
        color: $dark_blue;
      }
    }

    &.btn_blue {
      opacity: 1;
      background: $dark_blue;
      box-shadow: none;
      color: $white;
      &:hover:not(:disabled) {
        opacity: 0.8;
        background: $dark_blue;
        color: $white;
      }
      &:disabled {
        opacity: 0.8;
        cursor: default;
        background: $light-gray-3;
        box-shadow: none;
        color: $grey-5;
      }
    }
    &.btn_blue_shadow {
      opacity: 1;
      background: $dark_blue;
      box-shadow: 0 3px 10px 0 rgba($black, 0.08);
      color: $white;
      &:hover:not(:disabled) {
        opacity: 0.8;
        background: $dark_blue;
        color: $white;
      }
      &:disabled {
        opacity: 0.8;
        cursor: default;
        background: $light-gray-3;
        box-shadow: none;
        color: $grey-5;
      }
    }
    &.btn_dark_blue {
      opacity: 1;
      background: $dark_blue;
      box-shadow: none;
      color: $white;
      &:hover:not(:disabled) {
        opacity: 1;
        outline: none;
        box-shadow: inset 0 0 0 1px $dark_blue;
        text-decoration: none;
        background: $white;
        color: $dark_blue;
      }
    }
    &.btn_blue_border {
      opacity: 1;
      background: transparent;
      box-shadow: inset 0 0 0 1px $dark_blue;
      color: $dark_blue;
      &:hover:not(:disabled) {
        opacity: 0.8;
        background: transparent;
        color: $dark_blue;
      }
      &.btn_disabled,
      &:disabled {
        opacity: 0.8;
        pointer-events: none;
        cursor: default;
        background: transparent;
        box-shadow: inset 0 0 0 1px $light-gray-4;
        color: $grey-5;
      }
    }

    &.btn_blue_border.btn_full_hover {
      &:hover:not(:disabled) {
        opacity: 1;
        outline: none;
        text-decoration: none;
        background: $dark_blue;
        color: $white;
      }
    }

    &.btn_next:after,
    &.btn_prev:before {
      content: '';
      position: absolute;
      top: 50%;
      width: 10px;
      height: 10px;
      margin-top: -5px;
      border-style: solid;
      transform: rotate(-45deg);
    }
    &.btn_next:after {
      left: auto;
      right: 30px;
      border-width: 0 2px 2px 0;
      border-color: $white;
    }
    &.btn_prev:before {
      left: 30px;
      border-width: 2px 0 0 2px;
      border-color: $dark_blue;
    }
    &.btn_prev:before {
      left: 30px;
      border-width: 2px 0 0 2px;
      border-color: $dark_blue;
    }

    &.btn_white_shadow {
      min-width: 40px;
      opacity: 0.8;
      background-color: $white;
      border-radius: 3px;
      box-shadow: 0 3px 10px 0 rgba($black, 0.08);
      color: $color-content;
      &:hover {
        opacity: 1;
        background: $white;
        color: $color-content;
      }
    }
    &.btn_icon {
      padding: 0;
    }

    &.btn_red {
      background: $red;
      box-shadow: none;
      color: $white;
      &:hover {
        background: $red;
        color: $white;
      }
    }

    &.btn_orange {
      display: block;
      width: 280px;
      margin: 40px auto 0;
      background: $dark_blue;
      color: $white;
      opacity: 1;
      &:focus,
      &:active,
      &:hover {
        background: rgba($dark_blue, 0.9);
      }
    }
    &.btn_orange_border {
      display: block;
      width: 280px;
      margin: 20px auto 0;
      background: none;
      box-shadow: inset 0 0 0 1px $dark_blue;
      color: $dark_blue;
      opacity: 1;
      &:focus,
      &:active,
      &:hover {
        box-shadow: inset 0 0 0 1px rgba($dark_blue, 0.8);
        color: rgba($dark_blue, 0.8);
      }
    }
  }
  @media (min-width: 992px) {
    .btn {
      min-width: 140px;
      padding: 0 20px;
    }
  }
}

a {
  cursor: pointer;
  color: $bootstrap_blue;
  font-weight: 500;
  &:hover {
    opacity: 0.8;
  }
  &.white {
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
  }
}
