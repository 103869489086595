@import '../../assets/styles/styles';

.card.card_invite_user {
  margin-bottom: 20px;
  .card-body {
    padding-top: 15px;
    .role {
      margin-top: 40px;
    }
    .action_btn {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      .btn + .btn {
        margin-left: 10px;
      }
      .btn.btn-dark-blue {
        min-width: 150px;
      }
    }
  }
}
.card.card_users .card-body {
  padding: 0;
}

.users_list {
  margin-bottom: 0;
  min-height: 156px;
  max-height: calc(100vh - 205px);
  overflow: auto;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(#000, 0.1);
  }
  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(#000, 0.4);
  }
}
.user_box {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 24px;
  padding: 20px 0 15px;
  border-top: 1px solid rgba(#cecccc, 0.87);
  color: #3a3a3a;
  font-size: 14px;
  &:first-child {
    border-top: 0;
  }
  .btn_icon {
    width: 20px;
    min-width: 0;
    padding: 0;
    border: 0;
    box-shadow: none;
    background: none;
    cursor: pointer;
    img {
      width: 14px;
    }
    &:focus,
    &:hover,
    &:active,
    &:active:focus {
      background: none;
      box-shadow: none;
    }
    &:focus {
      outline: none;
    }
    &:not(:hover) img {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
  }
  + .user_box {
    margin-top: 0;
  }
}

.user_content {
  position: relative;
  width: calc(100% - 30px);
  padding-left: 54px;
  padding-top: 3px;
  .avatar {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    margin-right: 16px;
    width: 42px;
    min-width: 42px;
    height: 42px;
    border-radius: 4px;
    border: 1px solid #cecccc;
    background: white;
    overflow: hidden;
  }

  h2,
  span.email {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  h2 {
    margin: 0 0 2px;
    color: #3a414c;
    font-size: 14px;
  }
  p {
    position: relative;
    margin-bottom: 0;
    padding-right: 20px;
    color: #8c949e;
    font-size: 14px;
    line-height: 1;
  }
  .email {
    display: inline-block;
    max-width: calc(100% - 30px);
    margin-top: 2px;
  }
  .role {
    margin-top: 2px;
    color: #3a414c;
  }
  .warning {
    position: absolute;
    top: -1px;
    padding-left: 5px;
    img {
      width: 16px;
    }
  }
}
