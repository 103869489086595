.card {
  border: 0;
  border-radius: 5px;
  box-shadow: 5px 5px 5px 0 rgba(#000, 0.05);
  .card-header {
    height: 62px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 62px;
    background: #00506b;
    color: #fff;
    font-family: 'Averta-Semibold';
  }
  .card-body {
    padding: 30px 24px;
  }
}
