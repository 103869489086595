.ed-modal {
  .action_btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    .btn {
      margin-left: 15px;
    }
  }

  .form-control:disabled,
  .form-control[readonly] {
    color: #a1a1a1;
  }
}
