@import '../../assets/styles/variables';

.switch-component {
  display: flex;
  align-items: center;
  .switch {
    margin-right: 0;
  }
}
.switch_box {
  display: inline-flex;
  align-items: center;
  gap: 8px;

  &_right_label {
    flex-direction: row-reverse;
  }

  input[type='file']:focus,
  input[type='checkbox']:focus,
  input[type='radio']:focus {
    outline: none;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 22px;
    margin-bottom: 0;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid rgba(0, 64, 128, 0.15);
    background-color: $white;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: '';
      height: 16px;
      width: 16px;
      left: 2px;
      bottom: 2px;
      background-color: rgba(0, 64, 128, 0.15);
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    &.round {
      margin: 0;
      border-radius: 16px;
    }

    &.round:before {
      border-radius: 50%;
    }
  }

  input:checked + .slider {
    border-color: $primary;
    background-color: $primary;
  }

  input:checked + .slider:before {
    background-color: $white;
    transform: translateX(18px);
  }
}
