/*login form*/
.login_bg {
  height: 100vh;
  background: url(./img/bg.png) no-repeat;
  background-size: cover;
  background-position: center;
}
.login_form {
  position: absolute;
  z-index: 1;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 50px;
  min-width: 768px;
  width: 88vw;
  max-width: 1150px;
  min-height: 430px;
  height: 75vh;
  max-height: 560px;
  background: #fff url(./img/login-bg1.svg) no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  box-shadow: 18px 18px 0px 0px rgba(#fff, 0.5);
  .left_part {
    position: absolute;
    width: 43%;
    top: 110px;
    left: 0;
    text-align: center;
  }
  .right_part {
    position: absolute;
    top: 40px;
    bottom: 40px;
    right: 40px;
    width: 50%;
  }
  .logo {
    width: 56px;
    text-align: left;
  }
  .logo_icon {
    margin-top: -30px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    img {
      max-width: 500px;
    }
  }
  h1 {
    margin-bottom: 39px;
    color: #666666;
    font-size: 27px;
    line-height: 1.4;
  }
  p {
    margin-top: -10px;
    line-height: 1.5;
    color: #666666;
    a {
      white-space: nowrap;
      text-decoration: underline;
    }
    &.fs-19 {
      font-size: 19px;
      line-height: 30px;
    }
  }
}
.form {
  width: 300px;
  margin: 0 auto;
  .btn:not(.btn-pass) {
    width: 100%;
  }
  .form-group {
    margin-top: 5px;
  }
  .custom-control.custom-checkbox label {
    text-align: left;
  }
  .forgot-password {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 30px;
    a {
      color: #212529;
      &:hover {
        color: #707070;
      }
    }
  }
  .link-back {
    display: inline-block;
  }
}

@media (max-width: 992px) {
  .login_form {
    top: 0;
    bottom: 0;
    left: 15px;
    right: 15px;
    padding: 0;
    min-width: 0;
    width: auto;
    max-width: 500px;
    min-height: 0;
    max-height: 430px;
    box-shadow: none;
    .left_part {
      position: absolute;
      width: 100%;
      top: 90px;
      left: 50%;
      text-align: center;
      transform: translateX(-50%);
      h1 {
        font-size: 28px;
        font-weight: 400;
        line-height: 33px;
        max-width: 420px;
      }
    }
    .right_part {
      position: absolute;
      top: 40px;
      left: 50%;
      right: auto;
      bottom: auto;
      width: auto;
      transform: translateX(-50%);
    }
    .logo_icon {
      display: none;
    }
  }
}

@media (max-width: 992px) and (orientation: landscape) {
  .login_form {
    height: 100vh;
  }
  .login_form .left_part h1 {
    margin-bottom: 20px;
  }
}
